import {snakeCase} from "lodash";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {maxLength, minLength, required, email} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "agent-registration",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
        professional: 1,
        name: '',
        secondName: '',
        phone: '',
        work: '',
        files: [],
        city: '',
      },
      isValid: false,
      isValidTown: false,
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      name: {
        required,
      },
      secondName: {
        required,
      },
      phone: {
        required,
        minLength: minLength(13),
      },
      country: {
        required,
      },
      city: {
        required,
      },
      work: {
        required,
      },
      files: {
        required,
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      }
    }
  },
  watch: {},
  created() {
    this.onRequestTown(this.payload.city)
    this.getCountries()
  },
  computed: {
    ...mapGetters({
      loadingRegistration: 'auth/loadingRegistration',
      agentCode: 'auth/agentCode',
      towns: 'order/towns',
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    secondNameErrors() {
      let error = [];
      if (!this.$v.payload.secondName.$dirty) {
        return error;
      }
      if (!this.$v.payload.secondName.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.secondName) {
        this.validationErrors.secondName.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (this.payload.phone.length >= 3 && !this.$v.payload.email.minLength) {
        error.push(this.$t('validationMax').replace(':count', 13));
      }
      if (this.payload.phone.length === 13) {
        error = []
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    cityErrors() {
      let error = [];
      if (!this.$v.payload.city.$dirty) {
        return error;
      }
      if (!this.$v.payload.city.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    workErrors() {
      let error = [];
      if (!this.$v.payload.work.$dirty) {
        return error;
      }
      if (!this.$v.payload.work.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.work) {
        this.validationErrors.work.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    filesErrors() {
      let error = [];
      if (!this.$v.payload.files.$dirty) {
        return error;
      }
      if (!this.$v.payload.files.required) {
        error.push(this.$t('maxFileLoading'))
      }
      if (this.validationErrors.files) {
        this.validationErrors.files.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    }
  },
  methods: {
    ...mapActions({
      registrations: 'auth/REGISTRATION',
      getCountries: 'auth/GET_COUNTRIES',
      fetchTowns: 'order/NOVA_POSHTA_GET_TOWNS',
    }),
    ...mapMutations({
      changeRegisterAgent: 'popups/CHANGE_SHOW_SUCCESS_AGENT_REGISTER',
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
    }),
    onRequestTown(val) {
      const formData = JSON.stringify({
        "modelName": "Address",
        "calledMethod": "getCities",
        "methodProperties": {
          "FindByString": val
        },
        "apiKey": process.env.VUE_APP_NOVAPOSHTA_API_KEY
      })
      this.fetchTowns(formData).then(() => {
        const currentTown = this.towns.find(e => e.Description === this.payload.city)

        if (!currentTown) {
          return
        }

      })
    },

    handleFileChange(evt) {
      let arr = Array.from(evt.target.files)
      this.payload.files = this.payload.files.concat(arr)

    },
    removeFile(file) {
      const index = this.payload.files.findIndex(e => e.name === file.name)
      this.payload.files.splice(index, 1)
    },
    transformData() {
      let obj = {}
      for (let i in this.payload) {
        obj[snakeCase(i)] = this.payload[i]
      }
      return obj
    },
    parsFormData(obj) {
      let fd = new FormData();
      for (let i in obj) {
        if (i === 'files') {
          for (let j in obj[i]) {
            fd.append(`files[${j}]`, obj[i][j]);
          }
        } else {
          fd.append(i, obj[i])
        }
      }

      for (let i of fd.values()) {

      }

      return fd
    },
    formReset() {
      this.payload.work = ''
      this.payload.name = ''
      this.payload.secondName = ''
      this.payload.phone = ''
      this.payload.city = ''
      this.payload.files = []
      this.payload.email = ''
      this.$v.$reset()
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid && this.payload.phone.length === 13) {
        if (this.payload.files.length) {
          this.registrations(this.parsFormData(this.transformData())).then((res) => {

            this.changeRegisterAgent(true)
            setTimeout(() => {
              this.changeRegisterAgent(false)
              this.$router.push({name: 'main'}).then(() => {

              }).catch(() => {
                console.log()
              })
            }, 3000)
            this.formReset()
          }).catch((e) => {
            if (e.status === 422) {
              if (!e.data.data) {
                for (let i in e.data.errors) {
                  this.$toasted.error(e.data.errors[i]);
                }
              }
              for (let i in e.data.data.errors) {
                this.$toasted.error(e.data.data.errors[i]);
              }
            }

          })
        } else {
          // this.$toasted.error(this.$t('failLoadFiles'));
        }
      }
    }
  }
}
